<template>
  <v-card width="100%" outlined rounded="lg">
    <v-card-title class="font-weight-bold text-body-1 textPrimary--text py-2">{{
      $t('vendor.onboarding.returnedReasonTitle')
    }}</v-card-title>

    <v-card-text class="text-body-1">
      {{ reason }}
    </v-card-text>
    <v-divider class="mt-4" />
    <v-card-text class="text-caption py-2 font-weight-semibold">{{
      `* ${$t('vendor.onboarding.returnedReasonInstructions')}`
    }}</v-card-text>
    <!-- <v-card-text class="text-pre-wrap text-body-1 font-weight-semibold">{{
      reason
    }}</v-card-text> -->
  </v-card>
</template>

<script>
export default {
  name: 'OnBoardingReturnReason',
  props: {
    reason: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
